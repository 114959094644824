* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */

}

body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp"); */
  /* background-image: url('./Image/safar-safarov-MSN8TFhJ0is-unsplash.jpg'); */
  background-image: url('./Image/cyber4.jpg');
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


/* .container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
} */

.splitScreen {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}
.topPane{
  left: 0;
}
.downPane{
  right: 0;
}



article h1 {
  margin: 1rem 0;
}
article p{
  font-size: 26px;
}
