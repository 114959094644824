.social-container {
	/* background: #eee; */
	/* position:absolute; */
	left:0;
	bottom:0;
	right:0;
	/* padding-top: 50px; */
	padding-bottom:30px;
	/* justify-content: center; */
	
      }
      
       
.social-icon {
width: 20px;
height: 20px;
/* width: 50%;
height: 50%; */


margin: 0 5px;
text-align: center;
vertical-align: middle;
margin-left: 65px;
display: flex;
padding: 40px;

} 

a.social {
padding-top: 0;
width: 20px;
height: 20px;
margin: 0 5px;
transition: transform 250ms;
/* display: inline-block; */
vertical-align: middle;
display: flex;
align-items: center;

}

      a.social:hover {
	transform: translateY(-2px);
      }

      a.LinkedIn {
	color: #0072b1;
	
	
	
      }
      
      a.Gmail {
	color: orangered;
      }
      
      /* a.twitter {
	color: #49a1eb;
      } */
      
      a.Github {
	color: black;
      }