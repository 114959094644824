.blog-pre {
	margin-bottom: 3em !important;
	/* -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 35px -23px 2px -16px rgba(0, 0, 0, 0); */
	/* box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 35px -23px 2px -16px rgba(0, 0, 0, 0); */
	position: relative;
    }
    
    .code-copy-btn {
	color: white;
	position: absolute;
	right: 10px;
	top: -2px;
	font-size: 1.5em;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
    }
    
    .code-copy-btn:hover {
	transform: scale(1.1);
	opacity: 0.9;
    }
    